<template>
  <div id="add-room">
    <b-container>
      <b-row>
        <LayoutHeader />
        <b-col cols="4">
          <iq-card>
            <template v-slot:body>
              <div
                class="img-preview rounded mb-2 d-flex justify-content-center align-items-center"
              >
                <img
                  class="w-100 h-100"
                  v-if="imgPreview"
                  :src="imgPreview"
                  alt=""
                />
                <span v-else>{{ $t('img-preview') }}</span>
              </div>
              <div class="feild mb-2 border rounded p-2">
                <b-form-checkbox
                  value="1"
                  unchecked-value="0"
                  v-model.number="payload.smoking_policy"
                  name="check-button"
                  switch
                >
                  {{ $t('smoking-policy') }}
                </b-form-checkbox>
              </div>
            </template>
          </iq-card>
        </b-col>
        <b-col cols="8">
          <iq-card>
            <template v-slot:headerTitle>
               <h4>
                 {{ $t('add-new-room') }}
               </h4>
            </template>
            <template v-slot:headerAction> </template>
            <template v-slot:body>
              <b-form class="d-grid ga-5">
                <b-row>
                  <b-col sm="6" class="mb-2">
                    <div class="feild">
                        <label for="">{{ $t('upload-image') }}</label>
                      <b-form-file
                        max-height="300px"
                        type="text"
                        @change="getImg($event)"
                        required
                      ></b-form-file>
                    </div>
                  </b-col>
                  <!-- <b-col sm="12" md="6" class="mb-2" >
                    <div class="feild">
                      <label for="">Floor</label>
                      <v-select
                        v-model="payload.floor_id"
                        placeholder="Select Floor"
                        :options="floors.data"
                        :reduce="option => option.id"
                        label="number"
                      />
                    </div>
                  </b-col> -->
                  <b-col sm="12" md="6" class="mb-2">
                    <div class="feild">
                      <label for="">{{ $t('room-name') }}</label>
                      <b-form-input
                        type="text"
                        v-model.number="payload.name"
                        required
                      ></b-form-input>
                    </div>
                  </b-col>
                  <b-col sm="12" md="6" class="mb-2">
                    <div class="feild">
                      <label for="">{{ $t('adult-number') }}</label>
                      <b-form-input
                        type="number"
                        v-model.number="payload.adult_number"
                        required
                      ></b-form-input>
                    </div>
                  </b-col>
                  <b-col sm="12" md="6" class="mb-2">
                    <div class="feild">
                      <label for="">{{ $t('child-number') }}</label>
                      <b-form-input
                        type="number"
                        v-model.number="payload.child_number"
                        required
                      ></b-form-input>
                    </div>
                  </b-col>
                  <b-col sm="12" md="6" class="mb-2">
                    <div class="feild">
                      <label for="">{{ $t('double-Number') }}</label>
                      <b-form-input
                        type="number"
                        v-model.number="payload.duplex_bedroom_number"
                        required
                      ></b-form-input>
                    </div>
                  </b-col>
                  <b-col sm="12" md="6" class="mb-2">
                    <div class="feild">
                      <label for="">{{ $t('single-Number') }}</label>
                      <b-form-input
                        type="number"
                        v-model.number="payload.single_bedroom_number"
                        required
                      ></b-form-input>
                    </div>
                  </b-col>
                  <b-col sm="12" md="6" class="mb-2">
                    <div class="feild">
                      <label for="">{{ $t('child-bed-Number') }}</label>
                      <b-form-input
                        type="number"
                        v-model.number="payload.child_bedroom_number"
                        required
                      ></b-form-input>
                    </div>
                  </b-col>
                  <b-col sm="12" md="6" class="mb-2">
                    <div class="feild">
                      <label for="">{{ $t('bathroom-Number') }}</label>
                      <b-form-input
                        type="number"
                        v-model.number="payload.bathroom_number"
                        required
                      ></b-form-input>
                    </div>
                  </b-col>
                  <b-col sm="12" md="6" class="mb-2">
                    <div class="feild">
                      <label for="">{{ $t('kitchen-Number') }}</label>
                      <b-form-input
                        type="number"
                        v-model.number="payload.kitchen_number"
                        required
                      ></b-form-input>
                    </div>
                  </b-col>
                  <b-col sm="12" md="6" class="mb-2">
                    <div class="feild">
                      <label for="">{{ $t('like-this') }}</label>
                      <b-form-input
                        type="number"
                        v-model.number="payload.room_number"
                        required
                      ></b-form-input>
                    </div>
                  </b-col>
                  <b-col sm="12" class="mb-2">
                    <div class="controll py-2 d-flex justify-content-end">
                      <b-button variant="primary" @click="submit"
                        >{{ $t('save') }}</b-button
                      >
                    </div>
                  </b-col>
                </b-row>
              </b-form>
            </template>
          </iq-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import LayoutHeader from '@/components/hotelsComponents/LayoutHeader.vue'
export default {
  data () {
    return {
      imgPreview: '',
      payload: {
        image: '',
        name: '',
        adult_number: 0,
        bathroom_number: 0,
        kitchen_number: 0,
        child_bedroom_number: 0,
        single_bedroom_number: 0,
        duplex_bedroom_number: 0,
        apartment_type: 0,
        room_number: '',
        smoking_policy: 0
        // floor_id: ''
      }
    }
  },
  components: {
    LayoutHeader
  },
  methods: {
    getImg (event) {
      this.imgPreview = URL.createObjectURL(event.target.files[0])
      this.payload.image = event.target.files[0]
    },
    submit () {
      if (!this.payload.id) {
        this.addRooms(this.returnData(this.payload)).then(() => {
          this.payload = this.resetObject(this.payload)
          this.$router.push('/hotel-rooms')
        })
      } else {
        this.payload._method = 'PUT'
        this.updateRooms({
          id: this.payload.id,
          data: this.returnData(this.payload)
        }).then(() => {
          this.$router.push('/hotel-rooms')
        })
      }
    }
  },
  mounted () {
    this.getFloors()
    if (this.$route.params.id) {
      this.getRoom(this.$route.params.id).then(() => {
        Object.assign(this.payload, this.room)
        this.payload.smoking_policy = Number(this.payload.smoking_policy)
        this.payload.image = ''
        this.imgPreview = this.room.image_url
      })
    }
  }
}
</script>
<style>
.imgs-container.active{
    column-count: 3;
    column-gap: 5px;
}
.add-new-img {
    font-size: 18px;
    font-weight: bold;
    color: #ccc;
    height: 200px;
    border: 2px dashed #ccc;
    cursor: pointer;
}
.img-preview {
    height: 200px;
    border: 2px dashed #ccc;
}
.img-preview img {
    object-fit: contain;
}
</style>
